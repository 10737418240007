.header {
	background: $color-bg-color;
	transition: all ease-out .25s !important;

	position: fixed;
    top: 0;
	z-index: 999;
	width: 100%;
	padding: 20px 0;
	width: 100%;

	@include media-breakpoint-down(lg) {
		text-align: center;
	}

	@include media-breakpoint-down(md) {
		padding: 15px 0;
	}

	.btn {
		&:not(:last-of-type) {
			margin-right: 20px;
		}
	}

	&__box {
		width: 100%;
		display: flex;
	}

	.logo {
		@include media-breakpoint-up(md) {
			width: 30%;
		}
	}

	.nav {
		@include media-breakpoint-up(md) {
			width: 80%;
		}
	}

	&.hide {
		top: -110px;

		@include media-breakpoint-down(md) {
			top: -95px;
		}
	}
}