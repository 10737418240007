.nodes {
    height: 100%;
    min-height: 850px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        height: 100%;
        min-height: auto;
        flex-wrap: wrap;
        text-align: center;
        align-items: normal;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }

    &__inner {
        border-radius: 46px;
        padding: 80px 50px ;
        background-color: $color-bg-color-footer;

        @include media-breakpoint-down(lg) {
            padding: 50px 25px;
        }

        @include media-breakpoint-down(md) {
            padding: 50px 15px;
        }
    }

    &__ilu {
        vertical-align: middle;

        @include media-breakpoint-down(md) {
            margin: auto;
            float: none;
        }
    }

    .global-copy {
        margin-bottom: 35px;
        display: block;
        line-height: 22px;
    }

    .nodesimage {
        vertical-align: middle;
        margin: auto;
        padding-right: 30px;
        
        @include media-breakpoint-down(md) {
            padding-bottom: 20px;
            padding-right: 0;
        }
    }

    .main-list {
        padding: 50px;

        @include media-breakpoint-down(md) {
            padding: 50px 25px 50px;
        }
    }
}