/**
 * Breakpoints
 */

// Define media query breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 768px,
  lg: 1042px,
  xl: 1200px,
  xxl: 1680px,
  xxxl: 1880px
) !default;

// Define the maximum width of .container for different breakpoints.
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 1440px,
  xxl: 1440px
) !default;