.cms {
    padding: 50px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-family-headings;
        font-size: 20px;
        line-height: 30px;
        color: $color-white;

        margin-bottom: 20px;
    }

    strong {
        font-family: $font-family-headings;
        color: $color-white;
    }

    p,
    span,
    div,
    ul {
        font-family: $font-family-default;
        font-weight: $font-weight-medium;
        color: $color-typography-primary;
        font-size: 15px;
        line-height: 21px;
        text-align: left;

        @include media-breakpoint-down(sm) {
            font-size: 13px;
            line-height: 18px;
        }

        margin-bottom: 20px;

        a {
            color: $color-primary;
        }
    }

    ul {
        margin: 0 0 20px 30px;
    }
}