.success {
    height: 100%;
    min-height: 850px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    margin-bottom: 70px;
    margin-top: 50px;

    @include media-breakpoint-down(md) {
        height: 100%;
        min-height: auto;
        flex-wrap: wrap;
        text-align: center;
        align-items: normal;
        padding-top: 100px;
    }

    &__content {
        @include media-breakpoint-up(lg) {
            padding-left: 75px;
        }
    }

    &__inner {
        border-radius: 46px;
        padding: 60px 50px;
        background-color: $color-bg-color-footer;

        @include media-breakpoint-down(lg) {
            padding: 45px 25px;
        }

        @include media-breakpoint-down(md) {
            padding: 45px 15px;
        }
    }


    &__ilu {
        position: relative;
        margin-top: -100px;

        @include media-breakpoint-down(md) {
            margin: 0 auto 50px;
            float: none;
        }
    }

    .global-headline,
    .global-copy {
        max-width: 100%;
    }


    .global-copy {
        margin-bottom: 35px;
        display: block;
        line-height: 22px;
    }

    .discord {
        height: auto;
        min-height: 700px;
        width: 100%;
        border-radius: 15px;
    }
}