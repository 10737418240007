.modal {
    z-index: 9999999;

    &__overlay {
      z-index: 999;
    }

    &__close {
      @include transition($duration: .15s);
      color: $color-white;

      position: absolute;
      top: 10px;
      right: 10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;

      font-size: 18px;

      &::before {
        position: relative;
        left: 0px;
      }
    }

    .modal__content,
    .modal__header {
      max-width: 400px;
      margin: 40px auto;
    }

    .modal__container {
      background: $color-bg-color-footer;
      border-radius: 8px;
      margin: 0 15px;

      color: $color-white;
      position: relative;

      max-width: 600px;

      overflow-y: scroll;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
          display: none;
      }

      p {
        font-family: $font-family-default;
        font-size: 15px;
        line-height: 22px;
        color: rgba($color-white, 0.51);
      }

      &__logo {
        margin: 50px auto 0;
      }
    }

    &__header {
      justify-content: center !important;
      font-family: $font-family-headings;
      font-weight: $font-weight-bold;
      font-size: 20px;
      line-height: 24px;
      color: $color-white;
      margin: 20px 0 40px;
      display: block !important;
      text-align: center;
    }

    &__content {
      margin-top: 0 !important;
      text-align: left;
      color: $color-white !important;
      &__form {
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          font-size: 13px;
          font-family: sans-serif;
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          font-size: 14px;
          font-family: sans-serif;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
          font-size: 14px;
          font-family: sans-serif;
        }
      }

      .shop-box__btn {
        margin: 15px auto 0;
        text-align: center;
      }
    }
  }
