/**
 * Colors
 */

$color-white: #fff;
$color-black: #000;
$color-typography-primary: $color-white;

$color-nav-bg: #181b24;

$color-bg-color: #0F1118;
$color-bg-color-header: #0F1118;

$color-bg-button-shop: rgba(1, 101, 236, 0.2);
$color-bg-button-shop-copy: #338bff;
$color-bg-button-buy: #0065ec;
$color-bg-button-buy-me: #e22b76;
$color-bg-button-buy-me-hover: rgba(128, 0, 121, 0.489);

$color-bg-button-contact: #e6a35e;

$color-primary: #338bff;

$color-bg-color-button-dashboard: #338bff;

$color-bg-color-footer: #181b24;