.nav {
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100%;
		width: 100%;
		position: relative;
		flex-direction: row-reverse;
	}

	@include media-breakpoint-down(md) {
		padding-left: 15px;
		padding-right: 15px;
	}

	&-list {
		display: flex;
		flex-direction: row;
		margin: 0;

		&--dropdown {
            align-items: center;

			@include media-breakpoint-up(md) {
				background: rgba($color-nav-bg, .45);
				border-radius: 18px;
				padding: 35px 30px;
			}

			@include media-breakpoint-up(lg) {
				align-items: center;
				height: 48px;
			}

			@include media-breakpoint-down(md) {
				@include transition;
				opacity: 0;
				visibility: hidden;
				z-index: -1;

				align-items: baseline;
				display: flex !important;
				flex-flow: column;

				width: 100%;
				height: calc(100vh - var(--vh-offset, 0px));

			    position: fixed;
				left: 0;
				right: 0;

				background-color: $color-bg-color-header;
				z-index: 999;

				padding: 50px 0 0 0;
				margin: 60px auto 0;

			    .nav-list__item {
					padding: 0 20px;
					margin-bottom: 40px !important;

			    	a {
						display: block;

						@include media-breakpoint-down(md) {
							display: inline-block;
						}
					}

					&-small {
						margin-bottom: 5px !important;
					}
			    }
			}
		}

		&__item {
			&:not(:last-of-type) {
				margin-right: 60px;

				@include media-breakpoint-down(md) {
					margin: 0;
				}
			}

			&:nth-child(6n) {
				margin-right: 0;
			}

			&-seperator {
				margin-top: 15px !important;
			}

			&-link {
				@include transition;

				font-family: $font-family-default;
				font-weight: $font-weight-bold;
				font-size: 15px;
				color: $color-typography-primary;

				position: relative;

				&-small {
					font-size: 13px !important;
					line-height: 16px !important;
					margin-top: 15px;
				}

				@include media-breakpoint-only(lg) {
					font-size: 15px;
				}


				@include media-breakpoint-down(md) {
					font-size: 22px;
					line-height: 28px;
				}

				&::after {
					@include transition;
					content: '';
					display: block;
					border-bottom: 2px solid $color-bg-color-button-dashboard;
					transform: scaleY(0);

					position: relative;
					top: 2px;
				}

				&:hover {
					color: $color-bg-color-button-dashboard;
					text-decoration: none !important;

					&::after {
						transform: scaleX(1);
					}
				}
			}

			.btn {
				display: flex !important;
				align-items: center;

				@include media-breakpoint-down(md) {
					font-size: 22px;
					line-height: 28px;
				}

				svg {
					margin-right: 10px;
				}
			}

			&-burger {
				position: absolute;
				top: 30px;
				right: 20px;
				display: none;

				@include media-breakpoint-down(md) {
					display: block;
				}

				input {
					display: none;
				}

				&:hover {
					&::after {
						display: none;
					}
				}
			}
		}
	}
}