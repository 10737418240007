/*!
 * Project: P9 Solutions Website Frontend
 * Author: Kamil Czujowski
 * E-mail: dev@kamilczujowski.de
 * Website: https://empowers.studio
 */

/**
 * The structure is based on ITCSS with custom modifications.
 *
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported
 * to ensure the consistent increase of specificity.
 *
 *    ITCSS Methodology: Inverted Triangle CSS
 *
 *    ****************   1. Settings
 *     **************    2. Tools
 *      ************     3. Generic
 *       **********      4. Elements
 *        ********       5. Objects
 *         ******        6. Components
 *          ****         7. Modules
 *           **          8. Utilities
 *
 * 1. Settings
 *    Global configuration and variables.
 *    Breakpoints, colors, spacing, utilities etc.
 *
 * 2. Tools
 *    Functions and mixins.
 *
 * 3. Generic
 *    Ground zero styles. No classes.
 *
 * 4. Elements
 *    Unclassed (bare) HTML element.
 *    H1, Ul, A etc.
 *
 * 5. Objects
 *    Common non-cosmetic structural design patterns.
 *    Containers, rows, grids, colums etc.
 *
 * 6. Components
 *    Specific cosmetic elements of UI.
 *    Buttons, forms etc.
 *
 * 7. Modules
 *    Multi-part components.
 *    Navbar, footer etc.
 *
 * 8. Utilities
 *    Helpers and overrides.
 *
 *    Shame
 *    All the CSS, hacks and things we are not proud of.
 */

@import "1-settings/_breakpoints.scss";
@import "1-settings/_colors.scss";
@import "1-settings/_global.scss";
@import "1-settings/_spacing.scss";
@import "1-settings/_typography.scss";
@import "1-settings/_utilities.scss";
@import "2-tools/_aos.scss";
@import "2-tools/_breakpoints.scss";
@import "2-tools/_clearfix.scss";
@import "2-tools/_generate-utility.scss";
@import "2-tools/_make-responsive.scss";
@import "2-tools/_ratio.scss";
@import "2-tools/_transition.scss";
@import "3-generic/_print.scss";
@import "3-generic/_sanitize.scss";
@import "4-elements/_document.scss";
@import "4-elements/_forms.scss";
@import "4-elements/_headings.scss";
@import "4-elements/_helpers.scss";
@import "4-elements/_links.scss";
@import "4-elements/_lists.scss";
@import "4-elements/_media.scss";
@import "4-elements/_tables.scss";
@import "5-objects/_container.scss";
@import "6-components/_buttons.scss";
@import "6-components/_faq.scss";
@import "6-components/_footer.scss";
@import "6-components/_header.scss";
@import "6-components/_logo.scss";
@import "6-components/_main-list.scss";
@import "6-components/_menu-bars.scss";
@import "6-components/_modal.scss";
@import "6-components/_navigation.scss";
@import "7-modules/_cms.scss";
@import "7-modules/_features.scss";
@import "7-modules/_nodes.scss";
@import "7-modules/_opener.scss";
@import "7-modules/_success.scss";
@import "8-utilities/_micromodal.scss";
@import "8-utilities/_ratio.scss";
@import "8-utilities/_utilities.scss";
@import 'shame';
