/**
 * Headings
 */

 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6 {
   margin: 0;
   font-family: $headings-font-family;
   font-weight: $headings-font-weight;
   letter-spacing: $headings-letter-spacing;
   line-height: $headings-line-height;
   color: $headings-color;
   text-transform: $headings-text-transform;
 }

 h1,
 .h1 {
   @include make-responsive(font-size, $h1-font-size);
 }
 h2,
 .h2 {
   @include make-responsive(font-size, $h2-font-size);
 }
 h3,
 .h3 {
   @include make-responsive(font-size, $h3-font-size);
 }

.tag {
  font-weight: $font-weight-bold;
  border-radius: 8px;
  background-color: rgba(1, 101, 236, 0.2);
  padding: 10px 20px;
  color: $color-bg-button-shop-copy;
  margin-bottom: 20px;
  display: inline-block;
}

.global-headline {
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 30px;
  color: $color-white;
  font-weight: $font-weight-bold;

  @include media-breakpoint-down(lg) {
      font-size: 40px;
      line-height: 50px;
      max-width: 100%;
  }

  @include media-breakpoint-down(sm) {
      font-size: 26px;
      line-height: 36px;
  }
}

.global-copy {
  color: rgba($color-white, .44);
  max-width: 700px;

  font-size: 15px;
  line-height: 26px;

  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }

  @include media-breakpoint-down(sm) {
    font-size: 13px;
    line-height: 20px;
  }
}