.opener {
    height: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin-top: 110px;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        text-align: center;
        align-items: normal;
        margin-top: 80px;
    }

    @media screen {
        
    }

    &__imgbox,
    .container {
        position: relative;
        z-index: 999;
    }

    .container {
        z-index: 99;

        @include media-breakpoint-down(md) {
            margin-top: -200px;
        }

        @include media-breakpoint-down(xs) {
            margin-top: -75px;
        }
    }

    &__imgbox {
        position: relative;
        width: 100% !important;
        z-index: 0;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            position: absolute;
        }

        .animated-stage {
            width: 100% !important;

            @include media-breakpoint-up(xl) {
                max-width: 50%;
                float: right;
            }

            @include media-breakpoint-only(lg) {
                max-width: 500px;
                float: right;
            }

            img {
                @include media-breakpoint-up(xxl) {
                    float: right;
                }

                @include media-breakpoint-down(md) {
                    max-width: 600px;
                    float: right;

                    margin: 50px 0 100px;
                }

                @include media-breakpoint-down(xs) {
                    max-width: 95%;
                    margin-bottom: -90px;
                    margin-top: 30px;
                }
            }
        }
    }

    &__intro {
        font-weight: $font-weight-semi-bold;
        color: rgba($color-white, .21);

        font-size: 18px;
        line-height: 37px;

        @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 34px;
        }
    }

    &__headline {
        font-size: 56px;
        line-height: 70px;
        margin-bottom: 30px;
        color: $color-white;
        font-weight: $font-weight-bold;
        max-width: 90%;

        @include media-breakpoint-only(md) {
            font-size: 50px;
            line-height: 60px;
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        @include media-breakpoint-down(sm) {
            font-size: 28px;
            line-height: 36px;
        }

        span {
            position: relative;
            display: inline-block;

            &::after {
                @include transition;
					content: '';
					display: block;
					border-bottom: 3px solid $color-bg-color-button-dashboard;

					position: relative;
					top: 1px;
            }
        }
    }
}