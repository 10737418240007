.logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
    text-decoration: none;

	&__img {
		display: inline-block;
		width: 53px;
		height: 64px;

		@include media-breakpoint-down(sm) {
			max-width: 40px;
			height: 100%;
		}
	}

	span {
		font-family: $font-family-default;
		font-weight: $font-weight-bold;
		font-size: 17px;
		line-height: normal;
		color: $color-white;

		margin-left: 15px;
	}
}