// /**
//  * Buttons
//  */
$btn-font-weight: $font-weight-bold !default;
$btn-font-size: 15px !default;
$btn-font-size-mobile: 14px !default;

$btn-padding-x: 25px !default;
$btn-padding-y: 10px !default;

$line-height-base: 30px !default;


/* Common button styles */
.btn {
    font-family: $font-family-headings;
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
    color: $color-white;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    line-height: $line-height-base;
    letter-spacing: .2px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    border-radius: 8px;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    outline: 0;
    border: 0 !important;
    background: none;
    position: relative;
    z-index: 1;
    min-width: 140px;

    &:focus {
        outline: none;
    }

    > span {
        vertical-align: middle;
    }

    &:disabled {
        pointer-events: none;
        opacity: .5;
    }

    &:hover {
        text-decoration: none !important;
    }

    @include media-breakpoint-down(sm) {
        font-size: 13px;
    }
}


.btn--shop {
	overflow: hidden;
    color: $color-bg-button-shop-copy;
    padding: 10px $btn-padding-x;

    background: $color-bg-button-shop;
    transition: all .3s ease-out;
    min-width: auto;

    &:hover {
        background: $color-bg-button-shop-copy;
        color: $color-white;
    }
}

.btn--purchase {
	overflow: hidden;
    color: $color-white;
    padding: 12px $btn-padding-x;

    background: $color-bg-button-buy;
    transition: all .3s ease-out;
    min-width: auto;

    &:hover {
        background: $color-bg-button-shop;
        color: $color-bg-button-shop-copy;
    }
}

.btn--magiceden {
	overflow: hidden;
    color: $color-white;
    padding: 12px $btn-padding-x;
    margin-right: 12px;

    background: $color-bg-button-buy-me;
    transition: all .3s ease-out;
    min-width: auto;

    &:hover {
        background: $color-bg-button-buy-me-hover;
        color: $color-bg-button-buy-me;
    }
}

.btn--contact {
	overflow: hidden;
    color: $color-white;
    padding: 12px $btn-padding-x;

    background: $color-bg-button-contact;
    transition: all .3s ease-out;
    min-width: auto;

    &:hover {
        background: darken($color-bg-button-contact, 30%);
    }
}