label {
  font-family: $font-family-default;
  font-weight: $font-weight-semi-bold;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: block;
}

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-field {
  margin-bottom: 15px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 10px;
  }
}

.form-field__wrapper--input-checkbox {
  position: relative;
  margin-top: 10px;
  display: flex;
}

.input-unstyled {
  appearance: none;
  box-sizing: border-box;
  outline: 0;
  box-shadow: none;
  background: none !important;
  border: 0;
  border-radius: 0;
  font-size: 14px;
  text-align: center;
}

.input-checkbox {
    position: relative;
    top: 1px;
    margin-right: 8px;
}

.input-text {
  margin-bottom: 15px;
}


.input-text {
  font-family: $font-family-default;
  font-weight: $font-weight-semi-bold;
  appearance: none; // Removes awkward default styles on some inputs for iOS
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid rgba(201, 204, 200, 0.18);
  background-color: #141721;
  color: $color-white;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  padding: 0 15px;
  background-image: none !important;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.form-field {
  // general wrapper for form-field elements
  &__wrapper {
    margin-bottom: 10px;

    &.has-error {
      label {
        color: #a94442;
      }

      input {
        color: #a94442;
        border-color: #a94442;
      }
    }

    &--text,
    &--password,
    &--select,
    &--number {
      position: relative;
    }

    &-textarea {
      border: 0;
      position: relative;
    }
  }

  // general label-styling in form-fields
  &__label {
    font-family: $font-family-default;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
    line-height: 18px;
    display: inline-block;
    margin-bottom: 10px;
    color: $color-white;
    letter-spacing: 0.3px;

    &.has-error {
      color: #a94442;
    }
  }
}