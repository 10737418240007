/*
  Basic Modal Styles
*/

.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($color-black, .6);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal__container {
    background-color: #fff;
    padding: 1.25em;
    width: 100%;
    max-height: 100vh;
    border-radius: .3125em;
    overflow-y: auto;
    box-sizing: border-box;

    span {
      font-weight: 400;
    }
  }

  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal__title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25;
    color: $color-black;
    box-sizing: border-box;
  }

  .modal__close {
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
  }

  .modal__header .modal__close:before { content: "\2715"; }

  .modal__content {
    margin-top: 1rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    color: $color-black;
  }

  /*
    ANIMATIONS
  */
  @keyframes mmfadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes mmfadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }

  @keyframes mmslideIn {
    from { transform: translateY(15%); }
    to { transform: translateY(0); }
  }

  @keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
  }

  .micromodal-slide {
    display: none;
  }

  .micromodal-slide.is-open {
    display: block;
  }

  .micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide .modal__container,
  .micromodal-slide .modal__overlay {
    will-change: transform;
  }
