.main-list {
    list-style: none;
    margin: 0 0 50px 0;
    padding: 0;
    outline: 0;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(1, 1fr);
    }

    .list-item {
        display: flex;

        @include media-breakpoint-down(md) {
            text-align: left;
        }

        &__icon {
            border-radius: 8px;
            background-color: rgba(1, 101, 236, 0.2);
            padding: 10px 20px;

            margin-right: 20px;
            min-width: 69px;
            height: 64px;

            display: flex;
            align-items: center;

            @include media-breakpoint-down(md) {
                width: 55px;
                max-width: 55px;
                min-width: 55px;
                height: 50px;
            }
        }

        &__content {
            h4 {
                font-weight: $font-weight-bold;
            }

            p {
                color: rgba($color-white, .63);
                margin-bottom: 0;
            }
        }
    }
}