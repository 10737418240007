.footer {
    background-color: $color-bg-color-footer;
    padding: 40px 15px;
    box-shadow: 0 -2px 8px 1px rgba(8, 14, 24, 0.27);
    border-radius: 100px 100px 0 0;

    color: $color-white;
    font-weight: $font-weight-medium;

    font-size: 13px;
    margin-top: 100px;

    @include media-breakpoint-down(md) {
        padding: 15px 0;
        border-radius: 50px 50px 0 0;
    }

    a {
        color: $color-white;
        font-weight: $font-weight-semi-bold;

        &:hover {
            color: $color-primary;
            text-decoration: none;
        }
    }

    .logo {
        text-align: center;
        margin: 50px auto;

        @include media-breakpoint-down(md) {
            margin: 25px auto;
        }
    }

    &__social {
        display: flex;
        float: right;

        @include media-breakpoint-down(md) {
            float: none;
            justify-content: center;
            margin: 25px 0;
        }

        a {
            &:not(:last-of-type) {
                margin-right: 15px;
            }
        }
    }

    &__bottom {
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    .flex {
        align-items: flex-end;
    }
}