.features {
    height: 100%;
    min-height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        height: 100%;
        min-height: auto;
        flex-wrap: wrap;
        text-align: center;
        align-items: normal;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }

    &__inner {
        border-radius: 46px;
        padding: 40px 0 0;

        @include media-breakpoint-down(lg) {
            padding: 50px 15px 0;
        }

        @include media-breakpoint-down(md) {
            padding: 50px 15px 0;
        }
    }

    &__ilu {
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            margin-bottom: 50px;
        }
    }

    .global-copy {
        display: block;
        line-height: 22px;
    }

    .main-list {
        padding: 25px 50px 50px;

        @include media-breakpoint-down(md) {
            padding: 25px 25px 50px;
        }
    }

    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;

        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
        }
    }
      
    .grid-item {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 20px;

        @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr; /* Reset for responsiveness */
        }
    }
    
    .grid-image {
        max-height: 300px;
        height: auto;
        width: auto;
        vertical-align: middle;
    }

    .grid-item.dis{
        // reverse the order of the grid items
        @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;
        }
    }

    .grid-item-text {
        margin-top: 20px;
        margin-bottom: 20px;
        min-height: 115px;
        
        @include media-breakpoint-down(sm) {
            min-height: none;
        }
    }

}