.mobile--hidden {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.desktop--hidden {
    @include media-breakpoint-up(lg) {
        display: none !important;
    }
}

.is-hidden {
    display: none !important;
}

.desktop-left {
    @include media-breakpoint-up(md) {
        text-align: left;
    }
}

.is-visible {
    opacity: 1 !important;
    visibility: visible !important;
    z-index: 999 !important
}

.is-open {
    display: block !important;
}

.align-left {
    text-align: left;
}

.content-center {
    @include media-breakpoint-up(md) {
        align-items: center;
    }
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

strong {
    font-family: $font-family-default;
    font-weight: $font-weight-bold;
}

.w-mobile-first {
    @include media-breakpoint-down(md) {
        order: 1;
    }
}

.w-mobile-second {
    @include media-breakpoint-down(md) {
        order: 2;
    }
}

.w-mobile-third {
    @include media-breakpoint-down(md) {
        order: 3;
    }
}